import type { LinksFunction, LoaderArgs } from "@remix-run/node";
import { json } from "@remix-run/node";
import {
  isRouteErrorResponse,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useRouteError,
} from "@remix-run/react";

import tailwindStylesheetUrl from "./styles/tailwind.css";
import calendarStylesheetUrl from "./styles/Calendar.css";
import calendarPopUpStylesheetUrl from "./styles/CalendarPopUp.css";
import styles from "./styles/app.css";
import { getUser } from "./session.server";

export const links: LinksFunction = () => {
  return [
    { rel: "stylesheet", href: tailwindStylesheetUrl },
    { rel: "stylesheet", href: calendarStylesheetUrl },
    { rel: "stylesheet", href: calendarPopUpStylesheetUrl },
    { rel: "stylesheet", href: styles },
  ];
};

const additionalStyles = `
  input::-ms-reveal,
  input::-ms-clear {
    display: none;
  }
`;

export async function loader({ request }: LoaderArgs) {

  return json({
    user: await getUser(request),
  });
}

export function ErrorBoundary() {
  const error = useRouteError();

  // when true, this is what used to go to `CatchBoundary`
  if (isRouteErrorResponse(error)) {
    return (
      <div>
        <h1>KT 서비스 관련 오류</h1>
        <p>서비스 관련 오류입니다. 화면을 새로고침하시거나 웹사이트에 다시 접속해주세요</p>
      </div>
    );
  }
}

export default function App() {
  return (
    <html lang="en" className="h-full">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <style>{additionalStyles}</style>
        <Meta />
        <Links />
      </head>
      <body className=" h-full min-h-[700px] w-full min-w-[1400px] ">
        <Outlet />
        <ScrollRestoration />
        <Scripts />
        {/*<LiveReload />*/}
      </body>
    </html>
  );
}
